<script setup>
  import { computed } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'vuex';

  import MenuBar from "primevue/menubar"

  const route = useRoute();
  const router = useRouter();
  const store = useStore();
  const { t } = useI18n();

  function toggleDisplayFormat() {
    if (store.getters.getDisplayFormat === 'normal') {
      store.commit('setDisplayFormat', 'compact')
    } else {
      store.commit('setDisplayFormat', 'normal')
    }
  }

  const loginRoute = computed(() => {
    if (route.path.startsWith('/tournaments/')) {
      return {
        path: '/login',
        ...(route.path.startsWith('/tournaments/') && { query: { redirect: route.fullPath }})
      }
    }

    return { path: '/login' }
  })

  const items = computed(() => {
    if (store.getters.isAuthenticated) {
      return [
        {
          label: t('tournaments'),
          icon: 'fa-solid fa-chess-knight',
          command: () => router.push('/tournaments'),
        },
        {
          label: t('account'),
          icon: 'fa-solid fa-user',
          command: () => router.push('/dashboard'),
        },
        {
          label: t('supportUs'),
          icon: 'fa-solid fa-handshake-angle',
          command: () => window.open('https://buymeacoffee.com/alexis2t')
        },
        {
          label: t('reportBug'),
          icon: 'fa-solid fa-circle-exclamation',
          command: () => window.location.href = `mailto:contact+bugreport@pawnarena.com?subject=${subject}&body=${body}`,
        },
      ];
    } else {
      return [
        {
          label: t('users.login'),
          icon: 'pi pi-sign-in',
          command: () => router.push(loginRoute.value)
        },
        {
          label: t('supportUs'),
          icon: 'fa-solid fa-handshake-angle',
          command: () => window.open('https://buymeacoffee.com/alexis2t')
        },
        {
          label: t('reportBug'),
          icon: 'fa-solid fa-circle-exclamation',
          command: () => window.location.href = `mailto:contact+bugreport@pawnarena.com?subject=${subject}&body=${body}`,
        },
      ]
    }
  })

  const subject = t('mailBugSubject')
  const body = `- ${t('mailBugBodyStep1')}:%0D%0A%0D%0A
- ${t('mailBugBodyStep2')}:%0D%0A%0D%0A
- ${t('mailBugBodyStep3')}:`
</script>

<template>
  <MenuBar
  :model="items" class="justify-between"
  :pt="{
    root: {
        class: ['relative', 'flex', 'items-center', 'p-2', 'rounded-b-lg', 'bg-gradient-to-b from-deep-greyblue to-gray-900', 'border-0']
    },
    menu: ({ props }) => ({
        class: [
          // Flexbox
          'lg:flex',
          'items-center',
          'flex-wrap',
          'flex-col lg:flex-row',
          { hidden: !(props != null && props.mobileActive), flex: props == null ? void 0 : props.mobileActive },
          // Position
          'absolute lg:relative',
          'top-full left-0',
          'lg:top-auto lg:left-auto',
          // Size
          'w-full lg:w-auto',
          // Spacing
          'm-0',
          'py-1 lg:py-0 lg:p-0',
          'list-none',
          // Shape
          'shadow-lg lg:shadow-none',
          'border-0',
          // Color
          'bg-deep-greyblue lg:bg-transparent',
          // Misc
          'outline-none'
        ]
    }),
    menuitem: {
        class: 'lg:relative lg:w-auto w-full static'
    },
    content: ({ props, context }) => ({
        class: [
          // Shape
          { 'rounded-lg': props.root },
          //  Colors
          {
            'text-surface-500 dark:text-white/70': !context.focused && !context.active,
            'text-surface-500 dark:text-white/70': context.focused && !context.active,
            'text-primary-highlight-inverse': context.focused && context.active || context.active || !context.focused && context.active
          },
          // Hover States
          {
            'hover:bg-greyblue': !context.active,
            'hover:bg-greyblue text-primary-highlight-inverse': context.active
          },
          // Transitions
          'transition-all',
          'duration-200'
        ]
    }),
    action: ({ context }) => ({
        class: [
          'relative',
          // Flexbox
          'flex',
          'items-center',
          // Spacing
          'py-3',
          'px-5',
          // Size
          {
            'pl-9 lg:pl-5': context.level === 1,
            'pl-14 lg:pl-5': context.level === 2
          },
          'leading-none',
          // Misc
          'select-none',
          'cursor-pointer',
          'no-underline ',
          'overflow-hidden'
        ]
    }),
    icon: {
        class: 'mr-2'
    },
    submenuicon: ({ props }) => ({
        class: [
          {
            'ml-auto lg:ml-2': props.root,
            'ml-auto': !props.root
          }
        ]
    }),
    submenu: ({ props }) => ({
      class: [
        // Size
        'w-full lg:w-80',
        // Spacing
        'py-1',
        'm-0',
        'list-none',
        // Shape
        'shadow-none lg:shadow-lg',
        'border-0',
        // Position
        'static lg:absolute',
        'z-10',
        { 'lg:absolute lg:left-full lg:top-0': props.level > 1 },
        // Color
        'bg-deep-greyblue'
      ]
    }),
    separator: {
      class: 'border-t border-surface-200 dark:border-surface-600 my-1'
    },
    button: {
      class: ['flex lg:hidden', 'items-center justify-center', 'w-8', 'h-8', 'rounded-full', 'text-surface-500 dark:text-white/80', 'hover:text-deep-greyblue dark:hover:text-white/60', 'hover:bg-greyblue', 'transition duration-200 ease-in-out', 'cursor-pointer', 'no-underline']
    },
    end: {
      class: 'ml-auto self-center'
    }
  }">

    <template #start>
      <div class="flex">
        <RouterLink to="/" class="mr-32">
          <div class="flex gap-2 items-center w-10">
            <img src="/../assets/images/logo.png" alt="logo" width="40" />
            <h1 class="m-0 underline text-lg text-white">Pawn Arena</h1>
          </div>
        </RouterLink>
        <button class="primary-btn" @click="toggleDisplayFormat">
          <i class="fa-solid fa-table-cells-large" v-show="store.getters.getDisplayFormat !== 'compact'"></i>
          <i class="fa-solid fa-list-ul" v-show="store.getters.getDisplayFormat === 'compact'"></i>
        </button>
      </div>
    </template>
    <template #item="{item, props}">
      <a v-ripple class="lg:bg-transparent bg-deep-blue align-items-center text-light-grey no-underline hover:bg-greyblue ease-in-out duration-100 rounded-lg" v-bind="props.action">
        <i :class="item.icon"></i>
        <span class="ml-2">{{ item.label }}</span>
      </a>
    </template>
  </MenuBar>
</template>
